import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthJwtHelperService } from './auth-jwt-helper.service';
import { IAuthRefreshResponseDto } from '../../../shared/ystm-service-api-types';
import { catchError, map, take, tap } from 'rxjs/operators';
import { Observable, Subject, from, of } from 'rxjs';
import { environment } from '../../../../environments/environment';

export interface JwtPayload {
  type?: string;
  sub?: string;
  email?: string;
  status?: string;
  roles?: string[];
  iss?: string;
  iat?: number;
  exp?: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthTokenProviderService {

  /** role priorities */
  private rolePriority = ['ROOT', 'ADMIN', 'USER', 'GUEST'];

  /** Indicates whether the token is currently being refreshed. */
  private refreshTokenInProgress = false;
  /** Subject that will emit when the token has been refreshed. */
  private tokenRefreshed$: Subject<string | null> = new Subject();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly authJwtHelperService: AuthJwtHelperService
  ) { }

  public getAccessToken(): Promise<string | null> {
    const accessToken = localStorage.getItem("access_token");
    //console.log('access token: ', accessToken);
    // console.log('access token type: ', typeof accessToken);
    if (accessToken !== null && accessToken !== undefined && accessToken !== 'undefined') {
      if (!this.authJwtHelperService.isTokenExpired(accessToken, 5)) {
        //console.log('access token is valid');
        return new Promise((resolve) => resolve(accessToken));
      } else {
        //console.log('access token is expired');
        return this.refreshToken().toPromise();
      }
    }
    //console.log('no access token');
    return new Promise((resolve) => resolve(null));
  }

  public getRefreshToken(): string | null {
    const refreshToken = localStorage.getItem("refresh_token");
    if (refreshToken) {
      return refreshToken;
    }
    return null;
  }

  public refreshToken(): Observable<string | null> {
    //console.log('refreshing token')
    if (this.refreshTokenInProgress === true) {
      /** token refresh is in progress, wait for results */
      //console.log('refreshing token (1)');
      return this.tokenRefreshed$.pipe(take(1));
    } else {
      //console.log('refreshing token (2)')
      this.refreshTokenInProgress = true;
      /** retrieve refresh token from local storage, this is synchronous */
      const refreshToken = this.getRefreshToken();
      if (refreshToken) {
        //console.log('refreshing token (3)')
        return this.httpClient.post<IAuthRefreshResponseDto>(`${environment.ystmServiceBaseUrl}/auth/refresh`, { refreshToken }).pipe(
          map(res => {
            //console.log('refreshing token (3.a)')
            localStorage.setItem("access_token", res.accessToken);
            localStorage.setItem("refresh_token", res.refreshToken);
            this.tokenRefreshed$.next(res.accessToken);
            this.refreshTokenInProgress = false;
            return res.accessToken;
          }),
          catchError(() => {
            //console.log('refreshing token (3.a)')
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            this.tokenRefreshed$.next(null);
            this.refreshTokenInProgress = false;
            return of(null);
          })
        );
      } else {
        //console.log('refreshing token (4)')
        this.tokenRefreshed$.next(null);
        this.refreshTokenInProgress = false;
        return of(null);
      }
    }
  }

  public isLoggedIn(): Observable<boolean> {
    return from(this.getAccessToken()).pipe(
      map(accessToken => {
        if (accessToken) {
          return !this.authJwtHelperService.isTokenExpired(accessToken);
        }
        return false;
      })
    );
  }

  /**
   * Determine the role rank  of the current user.
   */
  public getRoleRank(): Observable<string | null> {
    return from(this.getAccessToken()).pipe(
      map(accessToken => {
        if (accessToken) {
          const payload = this.authJwtHelperService.decodeToken(accessToken) as JwtPayload;
          return this.rolePriority.find(role => payload.roles.includes(role)) || null;
        } else {
          return null;
        }
      })
    );
  }

  /**
   * Determine the role rank index of the current user.
   */
  public getRoleRankIndex(): Observable<number> {
    return from(this.getAccessToken()).pipe(
      map(accessToken => {
        if (accessToken) {
          const payload = this.authJwtHelperService.decodeToken(accessToken) as JwtPayload;
          return this.rolePriority.findIndex(role => payload.roles.includes(role)) ?? 99;
        } else {
          return 99;
        }
      })
    );
  }


  /**
   * Method to check whether the user is signed in.
   * @returns An observable that emits true if the user is signed in, false otherwise.
   */
  public isSignedIn(): Observable<boolean> {
    return from(this.getAccessToken()).pipe(
      map(accessToken => {
        if (accessToken) {
          return !this.authJwtHelperService.isTokenExpired(accessToken);
        }
        return false;
      })
    );
  }

  public setTokens(tokens: { accessToken: string, refreshToken?: string }): void {
    localStorage.setItem("access_token", tokens.accessToken);
    if(tokens.refreshToken) {
      localStorage.setItem("refresh_token", tokens.refreshToken);
    } else {
      localStorage.removeItem("refresh_token");
    }
  }

  public clearTokens(): void {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public signOut(): Observable<any> {
    const refreshToken = this.getRefreshToken();
    return this.httpClient.post<void>(`${environment.ystmServiceBaseUrl}/auth/sign-out`, { refreshToken }).pipe(
      tap(() => {
        this.clearTokens();
      }),
      catchError(() => {
        this.clearTokens();
        return of(null);
      })
    )
  }
}
